import { Builder } from '@builder.io/react';
import dynamic from 'next/dynamic';

import { config as alertConfig } from '../configurations/alertConfig';
import { config as articlesConfig } from '../configurations/articlesConfig';
import { config as assetsCounterConfig } from '../configurations/assetsCounterConfig';
import { config as benefitsV1Config } from '../configurations/benefitsV1Config';
import { config as buttonConfig } from '../configurations/buttonConfig';
import { config as cardSectionConfig } from '../configurations/cardSectionConfig';
import { config as checkboxConfig } from '../configurations/checkboxConfig';
import { config as contentCarouselConfig } from '../configurations/contentCarouselConfig';
import { config as dualMediaAndTextConfig } from '../configurations/dualMediaAndTextConfig';
import { config as faqConfig } from '../configurations/faqConfig';
import { config as featuredArtistsConfig } from '../configurations/featuredArtistsConfig';
import { config as footerHookConfig } from '../configurations/footerHookConfig';
import { config as genresConfig } from '../configurations/genresConfig';
import { config as prominentSearchConfig } from '../configurations/homepageProminentSearchConfig';
import { configV1 as hubspotV1 } from '../configurations/hubspotConfig';
import { configV2 as hubspotV2 } from '../configurations/hubspotConfig';
import { configV3 as hubspotV3 } from '../configurations/hubspotConfig';
import { config as imageAndTextConfig } from '../configurations/imageAndTextConfig';
import { config as imageCardsConfig } from '../configurations/imageCardsConfig';
import { config as logosConfig } from '../configurations/logosConfig';
import { config as mediaHeroConfig } from '../configurations/mediaHeroConfig';
import { config as moodsConfig } from '../configurations/moodsConfig';
import { config as newTagConfig } from '../configurations/newTagConfig';
import { config as pillsConfig } from '../configurations/pillsConfig';
import { config as pricingConfig } from '../configurations/pricingConfig';
import { config as searchBarConfig } from '../configurations/searchBarConfig';
import { config as searchHeroConfig } from '../configurations/searchHeroConfig';
import { config as seoTextAndImageConfig } from '../configurations/seoTextAndImageConfig';
import { config as seoTextConfig } from '../configurations/seoTextConfig';
import { config as sfxTagConfig } from '../configurations/sfxTagConfig';
import { config as siteMapCategoryConfig } from '../configurations/siteMapCategoryConfig';
import { config as siteMapConfig } from '../configurations/siteMapConfig';
import { config as stackConfig } from '../configurations/stackConfig';
import { config as staffPickTagConfig } from '../configurations/staffPickTagConfig';
import { config as storyblocksLogoConfig } from '../configurations/storyblocksLogoConfig';
import { config as tabConfig } from '../configurations/tabConfig';
import { config as tabsConfig } from '../configurations/tabsConfig';
import { config as testimonialsConfig } from '../configurations/testimonialsConfig';
import { config as textHeroConfig } from '../configurations/textHeroConfig';
import { config as thankYouConfig } from '../configurations/thankYouConfig';
import { config as videoHeroConfig } from '../configurations/videoHeroConfig';
import { config as vimeoConfig } from '../configurations/vimeoConfig';
import { config as wistiaConfig } from '../configurations/wistiaConfig';
import { config as youtubeConfig } from '../configurations/youtubeConfig';

Builder.registerComponent(
    dynamic(() => import('./Alert.builder')),
    alertConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Articles.builder')),
    articlesConfig,
);

Builder.registerComponent(
    dynamic(() => import('./AssetsCounter.builder')),
    assetsCounterConfig,
);

Builder.registerComponent(
    dynamic(() => import('./BenefitsV1.builder')),
    benefitsV1Config,
);

Builder.registerComponent(
    dynamic(() => import('./Button.builder')),
    buttonConfig,
);

Builder.registerComponent(
    dynamic(() => import('./CardSection.builder')),
    cardSectionConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Checkbox.builder')),
    checkboxConfig,
);

Builder.registerComponent(
    dynamic(() => import('./ContentCarousel.builder')),
    contentCarouselConfig,
);

Builder.registerComponent(
    dynamic(() => import('./DualMediaAndText.builder')),
    dualMediaAndTextConfig,
);

Builder.registerComponent(
    dynamic(() => import('./FAQ.builder')),
    faqConfig,
);

Builder.registerComponent(
    dynamic(() => import('./FeaturedArtists.builder')),
    featuredArtistsConfig,
);

Builder.registerComponent(
    dynamic(() => import('./FooterHook.builder')),
    footerHookConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Genres.builder')),
    genresConfig,
);

Builder.registerComponent(
    dynamic(() => import('./HomepageProminentSearch.builder')),
    prominentSearchConfig,
);

Builder.registerComponent(
    dynamic(() => import('./SearchHero.builder')),
    searchHeroConfig,
);

Builder.registerComponent(
    dynamic(() => import('./ImageAndText.builder')),
    imageAndTextConfig,
);

Builder.registerComponent(
    dynamic(() => import('./ImageCards.builder')),
    imageCardsConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Logos.builder')),
    logosConfig,
);

Builder.registerComponent(
    dynamic(() => import('./MediaHero.builder')),
    mediaHeroConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Moods.builder')),
    moodsConfig,
);

Builder.registerComponent(
    dynamic(() => import('./NewTag.builder')),
    newTagConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Pills.builder')),
    pillsConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Pricing.builder')),
    pricingConfig,
);

Builder.registerComponent(
    dynamic(() => import('./SeoText.builder')),
    seoTextConfig,
);

Builder.registerComponent(
    dynamic(() => import('./SeoTextAndImage.builder')),
    seoTextAndImageConfig,
);

Builder.registerComponent(
    dynamic(() => import('./SfxTag.builder')),
    sfxTagConfig,
);

Builder.registerComponent(
    dynamic(() => import('./SiteMap.builder')),
    siteMapConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Stack.builder')),
    stackConfig,
);

Builder.registerComponent(
    dynamic(() => import('./SiteMapCategory.builder')),
    siteMapCategoryConfig,
);

Builder.registerComponent(
    dynamic(() => import('./StaffPickTag.builder')),
    staffPickTagConfig,
);

Builder.registerComponent(
    dynamic(() => import('./StoryblocksLogo.builder')),
    storyblocksLogoConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Tab.builder')),
    tabConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Tabs.builder')),
    tabsConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Testimonials.builder')),
    testimonialsConfig,
);

Builder.registerComponent(
    dynamic(() => import('./TextHero.builder')),
    textHeroConfig,
);

Builder.registerComponent(
    dynamic(() => import('./ThankYou.builder')),
    thankYouConfig,
);

Builder.registerComponent(
    dynamic(() => import('./VideoHero.builder')),
    videoHeroConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Vimeo.builder')),
    vimeoConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Wistia.builder')),
    wistiaConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Youtube.builder')),
    youtubeConfig,
);

Builder.registerComponent(
    dynamic(() => import('./Hubspot.builder')),
    hubspotV1,
);

Builder.registerComponent(
    dynamic(() => import('./Hubspot.builder')),
    hubspotV2,
);

Builder.registerComponent(
    dynamic(() => import('./Hubspot.builder')),
    hubspotV3,
);

Builder.registerComponent(
    dynamic(() => import('./SearchBar.builder')),
    searchBarConfig,
);

Builder.register('insertMenu', {
    name: 'Storywind',
    items: [
        { name: 'Alert' },
        { name: 'Button' },
        { name: 'Checkbox' },
        { name: 'New tag' },
        { name: 'Sfx tag' },
        { name: 'Stack' },
        { name: 'Staff pick tag' },
        { name: 'Storyblocks logo' },
        { name: 'Tab' },
    ],
});
